import { Component } from "react"
import { Container } from '@material-ui/core';
import LoSentimosCard from '../common/LoSentimosCard'
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'

class LoSentimosAfi extends Component {
    constructor(props) {
        super(props)
    }

    saveGtmcolor(color){
        let puntaje
        switch (color){
          case 'BLUE':  puntaje='950'; break;
          case 'GREEN':  puntaje='950'; break;
          case 'YELLOW':  puntaje='300'; break;
          case 'ORANGE':  puntaje='250'; break;
          case 'GRAY_1':  puntaje='100'; break;
          case 'GRAY_2':  puntaje='0'; break;
          case 'RED':  puntaje='0'; break;
          default : puntaje='0'; break;
    
        }
        console.log("puntaje=>",puntaje)
        window.dataLayer.push({ 
                  'event': 'score', 
                  'color': `${color}`, 
                  'valorColor': `${puntaje}`
                });
      }

      componentDidMount() {        
        const flujo = JSON.parse(sessionStorage.getItem('flujo'))
        if (flujo?.datosAdicionales) {
            const { color } = flujo.datosAdicionales
            this.saveGtmcolor(color)
        }else{console.log("no hay datos adicionales en sessionStorage")} 
    }
    // function LoSentimosAfi({ companyName }) {

    render() {
        const {
            companyName,
            dataInfo
        } = this.props
        return (
            <Container justify="center">
                <LoSentimosCard data={dataInfo} companyName={companyName} />
            </Container>

        )
    }
}

export default withThemeProps(LoSentimosAfi, 'VDPNLoSentimosAfi')
LoSentimosAfi.propTypes = {
    companyName: PropTypes.string,
    dataInfo: PropTypes.object
}
LoSentimosAfi.defaultProps = {
    companyName: "Afi",
    dataInfo: {
        "textHeader": "Muchas gracias por tu interés en obtener un préstamo con nosotros.",
        "textBox": "En este momento no reúnes los criterios necesarios para la aprobación de tu solicitud, esperamos poder apoyarte en el futuro en Apoyo Financiero.",
        "textBoxTwo": "En los próximos días recibirás una comunicación por correo postal con más detalles, si tienes preguntas puedes llamar al 800-891-2778."
    }
}
// export default LoSentimosAfi

